import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import { RIGHT_INDENTS, TOP_INDENTS } from '../../../../../../../../../../../../../../constants';
import { ArrowGoingUpIcon } from '../../../../../../../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../../../../../../../UiLibrary';
import HtmlViewer from './components/HtmlViewer';
import StyledLink from './styled/StyledLink';
import StyledText from './styled/StyledText';
import { getProductUrl } from './utils';

const AMAZON_AFFILIATE = 'https://amzn.to/4h6NVov';

const getElemAttrs = (target = {}) => {
    const element = {
        tagName: target.tagName.toLowerCase()
    };

    element.className = 'amazon_test';
    element.src = target.src || undefined;
    element.href = target.href || undefined;

    return { element };
};

export const Details = ({ data, simple = false, brandLink, handleParameter }) => {
    const productUrl = useMemo(() => getProductUrl(brandLink, handleParameter), [brandLink, handleParameter]);
    return (
        <StyledText data-e2e='product-description' simple={simple} type='descriptor1' color='primaryText' as='div'>
            <HtmlViewer html={data} />
            <Row style={TOP_INDENTS.XL}>
                <Col>
                    <StyledLink
                        style={RIGHT_INDENTS.XL}
                        data-e2e='view-in-store-button'
                        href={productUrl}
                        target='_blank'
                    >
                        <Typography.Text as='div' type='badgeSmall'>
                            View in brand store
                        </Typography.Text>
                        <Icon size='16px' component={() => <ArrowGoingUpIcon />} />
                    </StyledLink>
                </Col>
                <Col>
                    <StyledLink
                        onClick={(e) => {
                            window.klpixel('event', 'click', getElemAttrs(e.currentTarget));
                        }}
                        href={AMAZON_AFFILIATE}
                        target='_blank'
                    >
                        <Typography.Text as='div' type='badgeSmall'>
                            View on Amazon
                        </Typography.Text>
                        <Icon size='16px' component={() => <ArrowGoingUpIcon />} />
                    </StyledLink>
                </Col>
            </Row>
        </StyledText>
    );
};
